(function ($) {

    /**
     * ANIMATIONS
     */

    let endScroll = false;
    // Init ScrollMagic
    var controller = new ScrollMagic.Controller();

    // get id of slides
    var headers = ['#home', '#about'];


    // change behaviour of controller to animate scroll instead of jump
    controller.scrollTo(function (newpos) {
        TweenMax.to(window, 1, {
            scrollTo: {
                y: newpos
            },
            ease: Power1.easeInOut,
            onComplete: () => {
                endScroll = false;
                //$(document.body).on('mousewheel DOMMouseScroll', onScroll);
            }
        });
    });

    function findCurrentId() {
        let currentScroll = $(window).scrollTop();
        let allSliders = $('section.slaid');
        for (let i = 0; i < allSliders.length; i++) {
            if ($(allSliders[i]).offset().top > currentScroll) {
                return i;
            }
        }
        return $(allSliders).length;
    }
    function moveItem(diff){
        let current = findCurrentId();
        if (diff> 0) {
            if (current > 0 && current < 8) {
                controller.scrollTo('#slaid' + (current + 1));
            } else {
                endScroll = false;
                $(document.body).on('wheel', onScroll);
            }
        } else {
            if (current > 1) {
                controller.scrollTo('#slaid' + (current - 1));
            } else {
                endScroll = false;
                $(document.body).on('wheel', onScroll);
            }
        }
    }
    $('section.slaid').on('touchstart', (event) => {
        window.touchY = event.changedTouches[0].clientY;
    });
    $('section.slaid').on('touchend', (event) => {
        let endTouchY = event.changedTouches[0].clientY;
        let diff = window.touchY - endTouchY;
        window.touchY = null;
        if(diff === 0){
            return;
        }
        moveItem(diff);
    });

    function onScroll(e) {
        $(document.body).off('wheel', onScroll);
        if (!endScroll) {
            endScroll = true;
            moveItem(e.originalEvent.deltaY);
        }
        e.preventDefault();
    }

    //$(document.body).on('wheel', onScroll);
    //  bind scroll to anchor links
    $(document).on("click", "a[href^='#']", function (e) {
        var id = $(this).attr("href");
        if ($(id).length > 0) {
            e.preventDefault();

            // trigger scroll
            controller.scrollTo(id);

            // if supported by the browser we can even update the URL.
            if (window.history && window.history.pushState) {
                window.history.pushState("", document.title, id);
            }
        }
    });






    // Animacje on scroll


    // loop through each .project element
    $('.slaid').each(function () {

        var h3 = this.children[0].children[0].children[0].children[1],
            h2 = this.children[0].children[0].children[0].children[0],
            cta = this.children[1],
            hex1 = this.children[2],
            hex3 = this.children[3],
            hex4 = this.children[4]


        var tlSlaid = new TimelineMax();
        tlSlaid
            .from(h3, 1, {
                y: '30px',
                autoAlpha: 0,
                ease: Power3.easeOut
            }, 0)
            .from(h2, 1, {
                y: '-20px',
                autoAlpha: 0,
                ease: Power3.easeOut
            }, 0)
            .from(cta, 0.5, {
                y: '-10px',
                autoAlpha: 0,
                ease: Power3.easeOut
            }, 0.1)
            .from(hex1, 1, {
                y: '350%',
                ease: Power3.easeOut
            }, 0)
            .from(hex3, 1, {
                y: '400%',
                ease: Power3.easeOut
            }, 0)
            .from(hex4, 1, {
                y: '450%',
                ease: Power3.easeOut
            }, 0)


        // build a scene
        var ourScene = new ScrollMagic.Scene({
            triggerElement: this.children[0],
            duration: 0,
            triggerHook: 0.4
        })
            .setTween(tlSlaid)
            .addTo(controller);

    });


    // loop through each .project element
    $('.slaid').each(function () {

        var h3 = this.children[0].children[0].children[0].children[1],
            h2 = this.children[0].children[0].children[0].children[0],
            cta = this.children[1],
            hex1 = this.children[2],
            hex3 = this.children[3],
            hex4 = this.children[4]


        var tlSlaid2 = new TimelineMax();
        tlSlaid2
            .to(h3, 1, {
                y: '-200%',
                ease: Power3.easeOut
            }, 0)
            .to(h2, 1, {
                y: '-300%',
                ease: Power3.easeOut
            }, 0)
            .to(cta, 1, {
                y: '-350%',
                ease: Power3.easeOut
            }, 0)
            .to(hex1, 1, {
                y: '-350%',
                ease: Power3.easeOut
            }, 0)
            .to(hex3, 1, {
                y: '-400%',
                ease: Power3.easeOut
            }, 0)
            .to(hex4, 1, {
                y: '-450%',
                ease: Power3.easeOut
            }, 0)


        // build a scene
        var ourScene2 = new ScrollMagic.Scene({
            triggerElement: this.children[0],
            duration: '100%',
            triggerHook: 'onLeave'
        })
            .setTween(tlSlaid2)
            .addTo(controller);

    });





    // slaid1
    // var  s1h3 = $('#slaid1 h3'),
    //      s1h2 = $('#slaid1 h2'),
    //      s1cta = $('#slaid1 .cta')
    //
    // var tlS1 = new TimelineMax();
    //     tlS1
    //         .from(s1h3, 1, {x: '300px', ease:Elastic.easeOut.config(1, 0.3)}, 0)
    //         .from(s1h2, 1, {x: '-200px', ease:Elastic.easeOut.config(1, 0.3)}, 0)
    //         .from(s1cta, 0.5, {y: '-100px',autoAlpha: 0 , ease:Power4.easeOut}, 0.1)
    //
    // var sceneS1 = new ScrollMagic.Scene({
    //             triggerElement: '#slaid1',
    //             duration: 0,
    //             triggerHook: 0.5,
    //             reverse: true
    //                 })
    //                 .setTween(tlS1)
    //                 .addTo(controller)
    //                 ;
    //
    // var tlS1b = new TimelineMax();
    //     tlS1b
    //          .to(s1h3, 1, {y: '-300%', ease:Power0.easeNone}, 0)
    //          .to(s1h2, 1, {y: '-200%', ease:Power0.easeNone}, 0)
    //          .to(s1cta, 1, {y: '-350%', ease:Power0.easeNone}, 0)
    //
    // var sceneS1b = new ScrollMagic.Scene({
    //             triggerElement: '#slaid1',
    //             duration: '90%',
    //             triggerHook: 'onLeave',
    //             reverse: true
    //                   })
    //                   .setTween(tlS1b)
    //                   .addTo(controller)
    //                   ;

    $('a[analitycs]').on('click',function(event){
        var target = event.target;
        if(target.localName == "img"){
            target = $(target).parent();
        }
        var mainCategory = $('body').attr('analitycs-category');
        var label = $(target).attr('analitycs');

        // ga('event', label, {
        //     'event_category': mainCategory,
        //     'event_label': label
        // });
        ga('lp.send', 'event', mainCategory, label);

    })
}(jQuery));